import type { ReactNode } from "react";
import type { ButtonProps } from "~/components/button";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import clsx from "clsx";
import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";

interface Props extends ButtonProps {
  label?: ReactNode;
  align?: "left" | "right" | "center";
  wrap?: boolean;
  caret?: boolean;
  up?: boolean;
}

export default function DropdownButton({
  label,
  className = "",
  align = "left",
  children,
  wrap = true,
  caret = true,
  up,
  ...rest
}: Props) {
  const content = (
    <Menu>
      <MenuButton
        as={Button}
        className={clsx("dropdown-toggle", className)}
        {...rest}
      >
        {label && caret ? <>{label} </> : label}
        {caret && <span className="caret" />}
      </MenuButton>
      <MenuItems
        modal={false}
        as="ul"
        className={clsx(
          "dropdown-menu !block focus:outline-none",
          align === "right" && "dropdown-menu-right"
        )}
      >
        {children}
      </MenuItems>
    </Menu>
  );

  if (wrap)
    return (
      <ButtonGroup className={up ? "dropup" : undefined}>{content}</ButtonGroup>
    );
  return content;
}
